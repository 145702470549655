import React, {Component} from 'react';
import logo from '../img/fox_t.png';

class Main extends Component {
    render() {
        return (
            <div id="home" className="main text-center container-fluid">
                <div className="fw-bold logo oswald"><img style={{maxHeight: "18rem", pointerEvents: "none", userSelect: "none"}} src={logo}/><br/><span>Lexikonn</span></div>
            </div>
        );
    }
}

export default Main;