import React, {Component} from 'react';
import UserForm from './ContactForm';

class Contact extends Component {

    render() {
        return (
            <div id="contact" className="container-fluid text-center dark-section">
                <div className="fw-bold fs-2">Kontakt</div>
                <a href="mailto:thatsme@lexikonn.cz" className="lead">thatsme@lexikonn.cz</a>
                <p className="mt-4">Nebo využijte formulář níže</p>
                <UserForm />
            </div>
        );
    }
}

export default Contact;