import logo from './logo.svg';
import './App.css';
import Navbr from './components/Nav';
import Main from './components/Main';
import About from './components/About';
import Contact from './components/Contact';
import Footer from './components/Footer';

function App() {
  return (
    <div className="App">
        <Navbr/>
        <Main/>
        <About/>
        <Contact/>
        <Footer/>
    </div>
  );
}

export default App;
