import { Container, Nav, Navbar } from 'react-bootstrap';

function Navbr() {
    return (
        <Navbar fixed="top" expand="lg" data-bs-theme="dark" className="lead nvbr">
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-center">
                    <Nav className="">
                        <Nav.Link href="#home">Domů</Nav.Link>
                        <Nav.Link href="#about">O mě</Nav.Link>
                        <Nav.Link href="#projects">Projekty</Nav.Link>
                        <Nav.Link href="#contact">Kontakt</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navbr;