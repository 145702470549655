import React, { useState } from "react";

function UserForm() {
    const [email, setEmail] = useState('');
    const [msg, setMsg] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        const webhookUrl = "https://discord.com/api/webhooks/979775257260982302/0Gm1d_bBtJMJOumrKWPAOcehZFGi4WWptwZHbQ7jxlYfA1QTRfgIGcFcFaFmG12YiTLh";

        const payload = {
            username: "LX NOTIF",
            avatar_url: "https://i.imgur.com/Wvom423.png",
            content: "<@338644247290970122>",
            embeds: [
                {
                    title: "Nová zpráva od uživatele!",
                    description: msg,
                    color: 0x5221b5,
                    fields: [
                        {
                            name: "Email",
                            value: email,
                            inline: true,
                        },
                    ],
                    footer: {
                        text: "Odesláno z Lexikonn.eu",
                    },
                    timestamp: new Date(),
                },
            ],
        };

        fetch(webhookUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
        })
            .then((response) => {
                if (response.ok) {
                    console.log("Zpráva odeslána!");
                    alert("Zpráva byla úspěšně odeslána");
                } else {
                    console.error("Chyba při odesílání zprávy:", response.statusText);
                    alert("Nastala chyba při odesílání zprávy.");
                }
            })
            .catch((error) => {
                console.error("Chyba:", error);
                alert("Nastala chyba při odesílání zprávy.");
            });
        setEmail('');
        setMsg('');
    };

    return (
        <form className="container mt-5" onSubmit={handleSubmit}>
            <input
                className="form-control mb-3"
                type="email"
                name="email"
                id="email"
                required
                placeholder="Zadejte svůj email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <textarea
                className="form-control mb-3"
                name="msg"
                placeholder="Napište zprávu"
                required
                value={msg}
                onChange={(e) => setMsg(e.target.value)}
            ></textarea>
            <button className="btn btn-primary" type="submit">
                Odeslat zprávu
            </button>
        </form>
    );
}

export default UserForm;
