import React, {Component} from 'react';
import project from './Projects';

class About extends Component {
    render() {
        return (
            <div id="about" className="container-fluid text-center dark-section">
                <div className="text-center my-5">
                    <h1 className="display-4 fw-bold">O mně</h1>
                    <p className="fs-5 text-light mx-auto" style={{maxWidth: "800px"}}>
                        Jsem nadšený <span className="text-primary">fullstack programátor</span>, který s vášní tvoří
                        komplexní webové aplikace a kreativní projekty.
                        Programování je nejen mou prací, ale také koníčkem, který rád propojuji s <span
                        className="text-primary">3D grafikou</span>.
                        Díky tomu mám příležitost realizovat nápady, které spojují technické dovednosti s uměleckým
                        pohledem.
                    </p>
                </div>

                <div id="projects" className="my-5 container">
                    <h2 className="text-center fw-semibold">Mé realizované projekty</h2>
                    <div className="row align-items-center justify-content-center mt-4">
                        {project("fantasyworldtrpg.cz", "https://fantasyworldtrpg.cz/img/system/icon.png", "Fantasy World TRPG", "Textové RPG prostředí pro fanoušky fantasy světa", "php")}
                        {project("trpgdatabase.cz", "https://trpgdatabase.cz/icon.png", "TRPG Database", "Databáze TRPG her pro snadné vyhledávání a sdílení", "php")}
                        {project("axilara.xyz", "https://axilara.xyz/logo_t.png", "Axilara BOT", "Administrativní bot pro Discord servery", "node")}
                        {project("hugoknives.cz", "https://hugoknives.cz/favicon.ico", "HUGOknives", "Nože na zakázku", "react")}
                        {project("nasefauna.cz", "https://nasefauna.cz/icon.png", "Naše Fauna", "Web zaměřený na inzerci zvířat a chovatelských potřeb", "php")}
                        {project("clicker.lexikonn.eu", "https://clicker.lexikonn.eu/favicon.ico", "Chaos clicker", "Oddechová prohlížečová hra - klikání", "react")}
                        {project("docs.lexikonn.eu/texteditor", "https://tools.lexikonn.eu/lexedit/icon.png", "LexEditor", "Textový editor s jednoduchým importem na web", "js")}
                        {project("docs.lexikonn.eu/lextools", "https://docs.lexikonn.eu/lextools/icon.png", "LexTools", "Developer tools pack software", "python")}
                        {/*project("clock.lexikonn.cz", "#", "HexClock", "Addon do Wallpaper Engine v podobě hodin", "js")*/}
                    </div>
                </div>

                <div className="text-center my-5">
                    <p className="fs-5 text-light mx-auto" style={{maxWidth: "800px"}}>
                        Mou silnou stránkou je schopnost vymýšlet a realizovat projekty od základů až po finální
                        produkt, a to s důrazem na detail a funkčnost.
                        Vždy hledám <span className="text-primary">originální řešení</span>, která osloví uživatele i
                        vývojáře.
                    </p>
                    <p className="fs-5 text-light mx-auto" style={{maxWidth: "800px"}}>
                        Miluji <span className="text-primary">výzvy</span> a neustále se učím nové technologie, které mě
                        posouvají kupředu.
                    </p>
                    <p className="fs-5 text-light mx-auto" style={{maxWidth: "800px"}}>
                        Mám bohaté zkušenosti s programováním v <span className="text-primary">PHP</span>, <span className="text-primary">JavaScriptu</span>, <span className="text-primary">React.js</span> a <span className="text-primary">Pythonu</span>.
                        Umím pracovat s relačními databázemi (<span className="text-primary">SQL</span>) a vytvářet komplexní aplikace. Neustále se zdokonaluji v moderních technologiích a přístupech, abych mohl tvořit efektivní a inovativní řešení.
                    </p>
                </div>
            </div>
        );
    }
}

export default About;