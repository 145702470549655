import React, {Component} from 'react';

class Footer extends Component {
    render() {
        return (
            <footer>
                &copy; 2025 Lexikonn.eu
            </footer>
        );
    }
}

export default Footer;