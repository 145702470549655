import React from 'react';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faPhp, faJs, faNodeJs, faPython } from '@fortawesome/free-brands-svg-icons';


function project(url, img, title, description, icon) {
    return (
        <Col lg={6} md={12}>
            <div className="project-card bg-dark text-light p-4 my-3 rounded"
                 style={{width: "100%"}}>
                <h3><img style={{userSelect: "none", pointerEvents: "none"}} className="pr-img" src={(img != "#" ? img : "/favicon.ico")}/> <strong>{title}</strong></h3>
                <p className="text-light">{description}</p>
                <p><a href={"https://" + url} target="_blank"><i
                    className="bi bi-globe2"></i> {url}</a></p>
                <p className="text-light fs-3">{(icon == "php" ? <FontAwesomeIcon title="PHP" style={{color: "#8993be"}} icon={faPhp} /> : (icon == "react" ? <FontAwesomeIcon title="React.js" style={{color: "#61DBFB"}} icon={faReact} /> : (icon == "js" ? <FontAwesomeIcon title="JavaScript" style={{color: "#f0db4f"}} icon={faJs} /> : (icon == "node" ? <FontAwesomeIcon title="Node.js" style={{color: "#68a063"}} icon={faNodeJs} /> : (icon == "python" ? <FontAwesomeIcon title="Python" style={{color: "#FFDE57"}} icon={faPython} /> : <></>)))))}</p>
            </div>
        </Col>
    );
}

export default project;